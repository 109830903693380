import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAgS_OnNRrKB0kQBboNRX3Izxf99IKDuNY",
  authDomain: "scheduler-88acd.firebaseapp.com",
  projectId: "scheduler-88acd",
  storageBucket: "scheduler-88acd.appspot.com",
  messagingSenderId: "337911000646",
  appId: "1:337911000646:web:fa984169dadc1b2c3d6447",
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
// // const timestamp = serverTimestamp();

const auth = getAuth();

export { db, serverTimestamp, auth };
