const adjectives = [
  "Adorable",
  "Amateur",
  "Amazing",
  "Astonishing",
  "Badass",
  "Beautiful",
  "Beautiful",
  "Breathtaking",
  "Creamy",
  "Cute",
  "Damm Hot",
  "Delightful",
  "Extraordinary",
  "Gorgeous",
  "Horny",
  "Hot",
  "Impressive",
  "Incredible",
  "Lovely",
  "Magnificent",
  "Mind-blowing",
  "Mind-boggling",
  "Miraculous",
  "Passionate",
  "Perfect",
  "Phenomenal",
  "Remarkable",
  "Sensual",
  "Sensual",
  "Sexy",
  "Skilled",
  "Skinny",
  "Staggering",
  "Stunning",
  "Superb",
  "Sweet",
  "Unbelievable",
  "Unique",
  "Wonderful",
];

export default adjectives;
