<template>
  <button @click="randomPost" class="glass dice">🎲</button>
  <div class="postnavigation">
    <button @click="prevPost">
      <span class="material-symbols-outlined"> arrow_back </span>
    </button>
    <div>
      <button
        id="saveBtn"
        v-if="accountName"
        @click="
          handleUpdate(
            choosenPost,
            choosenPost.title,
            socialType,
            accountName,
            getDate(socialType, accountName),
            choosenPost.tags,
            twitterAcc,
            false
          ),
            removeUsedTime(socialType, accountName),
            nextPost()
        "
      >
        <span class="material-symbols-outlined"> add </span>
      </button>
      <!-- <button id="copyBtn" v-if="accountName" @click="handleCopy(choosenPost)">
        <span class="material-symbols-outlined"> join_left </span>
      </button> -->
    </div>

    <button @click="nextPost">
      <span class="material-symbols-outlined"> arrow_forward </span>
    </button>
  </div>

  <div class="formContainer">
    <div v-if="posts">
      <a :href="createProfileUrl(choosenPost.url)" target="blank">
        <img :src="choosenPost.url"
      /></a>

      <div class="id">ID:{{ choosenPost.id }}</div>

      <div>
        <!-- <form @submit.prevent="handleSubmit"> -->
        <input
          :class="{ isScheduled: choosenPost.isScheduled }"
          type="text"
          name="title"
          id="title"
          placeholder="Title"
          v-model="choosenPost.title"
          @change="handleTagFromSentence(title), changeTitle(choosenPost.title)"
        /><br />

        <!-- <input
          v-if="!choosenPost.tags"
          type="text"
          name="tags"
          id="tags"
          placeholder="#tags-normal"
          v-model="tags"
          :class="{ error: !isTagsMinimum }"
          @change="checkIsTagsMinimum(makeArrFromTagsInput(tags))"
        /> -->

        <input
          type="text"
          name="tags"
          id="tags"
          placeholder="#tags"
          v-model="choosenPost.tags"
          @change="checkIsTagsMinimum(makeArrFromTagsInput(choosenPost.tags))"
        />
        <br />

        <input
          v-model="choosenAdjective"
          @click="copyToTitle(choosenPost.title)"
          @wheel.prevent="wheelItBetter($event, myData, myFunc)"
          style="width: 140px"
        />
        <span
          class="handler"
          @click="handleAdjective()"
          @wheel.prevent="wheelItBetter($event, myData, myFunc)"
          >:::</span
        >
        <input v-if="choosenPost.nick" v-model="choosenPost.nick" />

        <div class="tabs">
          <h3>reddit</h3>

          <div
            v-for="(accountsData, socialName) in socialsData"
            :key="socialName"
          >
            <!-- <span v-if="socialType == socialName"> -->
            <span v-if="socialName == 'reddit'">
              <span :key="accounts" v-for="(tags, accounts) in accountsData">
                <button
                  :id="accounts"
                  class="accountsTab"
                  :class="
                    accountName == accounts ? 'chosenMaintag' : 'nonactive'
                  "
                  @click="
                    accountName = accounts;
                    flair = '';
                    handleRefreshTagInput();
                  "
                >
                  {{ accounts }}

                  <span
                    v-for="accountsCount in neededToday"
                    :key="accountsCount"
                  >
                    <span
                      v-for="(value, accountNameCount) in accountsCount"
                      :key="accountNameCount"
                    >
                      <span
                        v-if="value != '0' && accountNameCount == accounts"
                        class="cont"
                        @click="handleRefreshTagInput()"
                        >{{ value }}</span
                      >
                    </span>
                  </span>
                </button>
              </span>
            </span>
          </div>
        </div>
        <div class="tabs">
          <div
            v-for="(accountsData, socialName) in socialsData"
            :key="socialName"
          >
            <span v-if="socialType == socialName" :class="accountName">
              <span :key="accounts" v-for="(tags, accounts) in accountsData">
                <span v-if="accountName == accounts">
                  <button
                    :key="tagname"
                    v-for="tagname in tags"
                    class="tag"
                    :class="{ chosenTag: flair == tagname }"
                    @click="
                      (flair = tagname),
                        addTag(tagname.replace(/\s+/g, '')),
                        handleRefreshTagInput()
                    "
                  >
                    {{ tagname }}
                  </button>
                </span>
              </span>
            </span>
          </div>
        </div>

        <!-- <hr /> -->
        <div class="tabs">
          <h3>Twitter</h3>

          <div
            v-for="(accountsData, socialName) in socialsData"
            :key="socialName"
          >
            <!-- <span v-if="socialType == socialName"> -->
            <span v-if="socialName == 'twitter'">
              <span :key="accounts" v-for="(tags, accounts) in accountsData">
                <button
                  :id="accounts"
                  class="accountsTab"
                  :class="
                    twitterAcc == accounts ? 'chosenMaintag' : 'nonactive'
                  "
                  @click="(twitterAcc = accounts), handleRefreshTagInput()"
                >
                  {{ accounts }}
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="todoInfo">
        <span v-if="posts">N° {{ postNum + 1 }} / {{ posts.length }}</span>
      </div>

      <button
        id="saveBtn2"
        @click="
          handleUpdate(
            choosenPost,
            choosenPost.title,
            socialType,
            accountName,
            ['2000-01-01', '00:00'],
            choosenPost.tags,
            twitterAcc,
            true
          )
        "
      >
        <span class="material-symbols-outlined"> save </span>
      </button>

      <div class="datatime">
        <input
          type="date"
          id="postDate"
          name="text"
          v-model="postDate"
          class="date"
          required
        />
        <input
          type="time"
          id="postTime"
          name="text"
          v-model="postTime"
          required
        />
      </div>
      <!-- <button
        @click="
          handleSaveTag(choosenPost, socialType, accountName, tags, twitterAcc)
        "
      >
        save tags
      </button> -->
      <!-- </form> -->

      <!-- <p>TODAY: {{ today }}</p> -->
      <!-- <p>NEXT DAY: {{ nextday }}</p> -->
      <!-- 
      <div style="display: block">
        <hr />
        <strong>FORM</strong>
        <p>Title {{ title }}</p>
        <p>#tags {{ tags }}</p>
        <p>Date: {{ postDate }}</p>
        <p>Time: {{ postTime }}</p>
        <p>SocialType: {{ socialType }}</p>
        <p>accountName: {{ accountName }}</p>
        <p>flair: {{ flair }}</p>
        <p>twitterAcc: {{ twitterAcc }}</p>
        <hr />
        <strong>FiIREBASE</strong>
        <p>Title {{ choosenPost.title }}</p>
        <p>#tags {{ choosenPost.tags }}</p>
        <p>Date: {{ choosenPost.data[0] }}</p>
        <p>Time: {{ choosenPost.data[1] }}</p>
        <p>twitter: {{ choosenPost.twitter }}</p>
        <p>SocialType: {{ choosenPost.socialType }}</p>
        <p>accountName: {{ choosenPost.accountName }}</p>
        <p>flair: {{ choosenPost.flair }}</p>
        <p>nsfw: {{ choosenPost.nsfw }}</p>
        <p>&nbsp;</p>
        <p>Agenda ID: {{ dates[0].id }}</p>
        <p>Agenda date: {{ dates[0].date.toDate() }}</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    -->
      <div
        style="font-size: 14px; line-height: 18px; display: block"
        v-if="choosenPost"
      >
        <div class="newDate">
          <!-- <div>{{ dateInUse }}</div>
          <button @click="nextDay(newDayToAdd, '-')">
            <span class="material-symbols-outlined"> arrow_back </span>
          </button>
          <button @click="addNewDayWithTimes">
            <span class="material-symbols-outlined"> calendar_add_on </span>
            {{ format(newDayToAdd, "RRRR-MM-dd") }}
          </button>
          <button @click="nextDay(newDayToAdd, '+')">
            <span class="material-symbols-outlined"> arrow_forward </span>
          </button>
          <button class="emoveBtn" @click="makeDayDone()">
            <span class="material-symbols-outlined"> event_available </span
            >{{ dateUsingNow }}
          </button> -->

          <button class="emoveBtn" @click="handleStartNewDay()">
            <span class="material-symbols-outlined"> event_available </span>
            Close {{ dateUsingNow }} /
            <span class="material-symbols-outlined"> calendar_add_on </span>
            Start Next
          </button>
        </div>

        <div>
          <button class="removeBtn" @click="removeGif(choosenPost.id)">
            remove this GIF
          </button>
        </div>

        <div style="display: block">
          <hr />
          <strong>FORM</strong>
          <p>Title {{ title }}</p>
          <p>#tags {{ tags }}</p>
          <p>Date: {{ postDate }}</p>
          <p>Time: {{ postTime }}</p>
          <p>SocialType: {{ socialType }}</p>
          <p>accountName: {{ accountName }}</p>
          <p>flair: {{ flair }}</p>
          <p>twitterAcc: {{ twitterAcc }}</p>
          <p>&nbsp;</p>
          <strong>FiIREBASE</strong>
          <p>Title {{ choosenPost.title }}</p>
          <p>#tags {{ choosenPost.tags }}</p>
          <p>Date: {{ choosenPost.data[0] }}</p>
          <p>Time: {{ choosenPost.data[1] }}</p>
          <p>twitter: {{ choosenPost.twitter }}</p>
          <p>SocialType: {{ choosenPost.socialType }}</p>
          <p>accountName: {{ choosenPost.accountName }}</p>
          <p>flair: {{ choosenPost.flair }}</p>
          <p>nsfw: {{ choosenPost.nsfw }}</p>
          <p>&nbsp;</p>
          <p>Agenda ID: {{ dates[0].id }}</p>
          <p>Agenda date: {{ dates[0].date.toDate() }}</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      <!-- <li v-for="post in posts" :key="post.id">
      {{ post.status }} — {{ post.url }}
    </li> -->
    </div>
    <div v-if="!posts">There is no posts todo ;(</div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { db, serverTimestamp } from "../firebase/config";

// import {
//   collection,
//   query,
//   where,
//   limit,
//   orderBy,
//   onSnapshot,
// } from "firebase/firestore";

import {
  doc,
  deleteDoc,
  updateDoc,
  arrayRemove,
  collection,
  addDoc,
} from "firebase/firestore";
import adjectives from "../configAdjectives";
import socials from "../configSocials";
import timings from "../configTimings";
import titleKeywords from "../configTitleKeywords";
import { format } from "date-fns";
import addDays from "date-fns/addDays";
import { onMounted, watchEffect, onBeforeMount } from "@vue/runtime-core";

export default {
  props: ["posts", "dates"],
  setup(props) {
    const choosenAdjective = ref("");
    const newDayToAdd = ref("");

    const today = format(new Date(), "RRRR-MM-dd");
    // console.log(typeof today);
    // console.log(today);
    newDayToAdd.value = new Date(today);
    // newDayToAdd.value = new Date("2022-11-22");
    const commentTxt = " — [ more in comment ]";

    const isTagsMinimum = ref(true);
    const dates = ref("");
    const dateUsingNow = ref("");

    const socialsData = ref("");
    socialsData.value = socials;
    const timingsData = ref("");
    timingsData.value = timings;
    // console.log(socialsData.value);
    const neededToday = ref([]);

    const title = ref("");
    const postDate = ref("");
    const postTime = ref("");
    const socialType = ref("reddit");
    // const socialType = ref("");
    const accountName = ref("");
    const twitterAcc = ref("");
    const flair = ref("");
    const nsfw = ref(true);
    const tags = ref("");
    postTime.value = "00:00";
    postDate.value = "2000-01-01";

    let choosenPost = ref("");
    let postNum = ref(0);
    let maxNum = 0;

    // console.log(props.dates["0"]);
    // console.log(props.dates["0"].date);

    //FIXME
    watchEffect(() => {
      dateUsingNow.value = format(
        new Date(props.dates["0"].date.seconds * 1000),
        "yyyy-MM-dd"
      );
    });

    // onBeforeMount(() => {
    //   dateUsingNow.value = format(
    //     new Date(props.dates["0"].date.seconds * 1000),
    //     "yyyy-MM-dd"
    //   );
    // });

    watchEffect(() => {
      if (props.posts) {
        choosenPost.value = props.posts[postNum.value];
        dates.value = props.dates;
        console.log("dates.value", dates.value);
      }
    });

    // const handleClick = () => {
    //   console.log("click");
    //   this.active = !this.active;
    // };

    const nextDay = (inputDate, direction) => {
      if (direction == "+") {
        newDayToAdd.value = addDays(new Date(inputDate), 1);
      } else {
        newDayToAdd.value = addDays(new Date(inputDate), -1);
      }
    };

    const returnNextDay = (inputDate, direction) => {
      if (direction == "+") {
        return addDays(new Date(inputDate), 1);
      } else {
        return addDays(new Date(inputDate), -1);
      }
    };

    const nextHour = (time) => {
      const timeArr = time.split(":");
      console.log(time);
      console.log(timeArr);
    };

    const addCommentTxt = (title) =>
      title.includes(commentTxt) ? title : title + commentTxt;

    const convertDate = (RRRRMMDD) => {
      // 2022-12-08  - 8 December  2022 // RRRR MM DD => MM DD RRRR // 11122022
      const dateArr = RRRRMMDD.split("-");
      return `${dateArr[1]}${dateArr[2]}${dateArr[0]}`;
    };

    const dateToInputs = (dateAndTimeData) => {
      if (dateAndTimeData.length > 0) {
        let year = dateAndTimeData[0].slice(4, 8);
        let day = dateAndTimeData[0].slice(2, 4);
        let month = dateAndTimeData[0].slice(0, 2);
        return `${year}-${month}-${day}`;
      }
    };

    const timeToInputs = (dateAndTimeData) => {
      if (typeof dateAndTimeData === "string") {
        // rob dla stringa
        // console.log("dateAndTimeData is a SRTING");
        let ampm = dateAndTimeData.slice(-2);
        let minutes = dateAndTimeData.slice(2, 4);
        let hour = dateAndTimeData.slice(0, 2);

        if (ampm === "PM") {
          // console.log(`${+hour + 12}:${minutes}`);
          return `${+hour + 12}:${minutes}`;
        } else {
          // console.log(`${hour.padStart(2, "0")}:${minutes}`);
          return `${hour.padStart(2, "0")}:${minutes}`;
        }
      } else {
        if (dateAndTimeData.length > 0) {
          let ampm = dateAndTimeData[1].slice(-2);
          let minutes = dateAndTimeData[1].slice(2, 4);
          let hour = dateAndTimeData[1].slice(0, 2);

          if (ampm === "PM") {
            // console.log(`${+hour + 12}:${minutes}`);
            return `${+hour + 12}:${minutes}`;
          } else {
            // console.log(`${hour.padStart(2, "0")}:${minutes}`);
            return `${hour.padStart(2, "0")}:${minutes}`;
          }
        }
      }
    };

    const convertTime = (TTTT) => {
      console.log(TTTT);
      // console.log(TTTT.value);

      const timeArr = TTTT.split(":");
      if (timeArr[0] <= 12) {
        return `${timeArr[0]}${timeArr[1]}AM`;
      } else {
        let hour = String(timeArr[0] - 12);
        let newHour = hour.padStart(2, "0");
        return `${newHour}${timeArr[1]}PM`;
      }
    };

    const createProfileUrl = (gifUrl) => {
      let nickname = gifUrl.split("/")[6];
      return `https://web2sex.com/models/${nickname}.html`;
    };

    const removeUsedTime = async (socialType, accountName) => {
      let id = props.dates[0].id;
      let date = dates.value["0"];

      const docRef = doc(db, "agenda", id);

      let timesObj = date[`${socialType}-${accountName}`];
      // console.log(timesObj);

      let count = Object.keys(timesObj).length;
      console.log(count);

      let timeValue = timesObj["0"];

      if (count <= 1) {
        await updateDoc(docRef, {
          // [`${socialType}-${accountName}.isDone`]: true,
          [`${socialType}-${accountName}`]: arrayRemove(timeValue),
        });
      } else {
        await updateDoc(docRef, {
          [`${socialType}-${accountName}`]: arrayRemove(timeValue),
        });
      }
    };

    const getDate = (socialType, accountName) => {
      console.log("getDate", socialType, accountName);
      let date = dates.value["0"];
      let availableTime = date[`${socialType}-${accountName}`][0];

      if (availableTime) {
        let result = date["date"].toDate();
        let foundDate = format(result, "yyyy-MM-dd");
        let foundTime = timeToInputs(availableTime);
        console.log("foundDate, foundTime", foundDate, foundTime);
        return [foundDate, foundTime];
      } else {
        console.log("Nie ma godziny do tego accounta");
        return;
      }
    };

    let checker = (arr) => arr.every((v) => v === "0");

    // FIXME
    const isDayDone = () => {
      // console.log("isDayDone");
      // console.log("neededToday.value", neededToday.value);
      let tempArr = [];
      // console.log("neededToday.value.length", neededToday.value.length);
      if (neededToday.value.length > 0) {
        for (const item of neededToday.value) {
          console.log(item);
          let value = Object.values(item);
          // console.log(value[0]);
          tempArr.push(value[0]);
        }
      } else {
        console.log("neededToday.value.length <= 0", neededToday.value.length);
      }
      console.log(tempArr);
      console.log("isDayDone:", checker(tempArr));

      return checker(tempArr);
    };

    const addNewDayWithTimes = async () => {
      console.log("addNewDayWithTimes");
      let timings = timingsData.value;
      console.log(timings);
      // TODO sprawdz ostatni zrobiony dzien

      // console.log(datesDone["date"].toDate());
      let newTimingDay = {};
      // console.log(newTimingDay);

      for (const social in timings) {
        // console.log(`${social}`);
        for (const [account, value] of Object.entries(timings[social])) {
          // console.log(`${social} — ${account}: ${value}`);
          // console.log(value);
          let timeArray = Object.values(value);
          console.log("timeArray", timeArray);

          newTimingDay[`${social}-${account}`] = timeArray;
        }
      }

      let newDay = newDayToAdd.value;
      newTimingDay["isDone"] = false;
      newTimingDay["date"] = newDay;
      newTimingDay["createdAt"] = serverTimestamp();

      console.log(newTimingDay);

      const colRef = collection(db, "agenda");

      // Add a new document with a generated id.
      const docRef = await addDoc(colRef, newTimingDay);
      console.log("Document written with ID: ", docRef.id);
    };

    const addNewDayWithTimesByDate = async (dateToAdd) => {
      let timings = timingsData.value;
      let newTimingDay = {};

      for (const social in timings) {
        for (const [account, value] of Object.entries(timings[social])) {
          let timeArray = Object.values(value);
          console.log("timeArray", timeArray);
          newTimingDay[`${social}-${account}`] = timeArray;
        }
      }

      newTimingDay["date"] = dateToAdd;
      newTimingDay["isDone"] = false;
      newTimingDay["createdAt"] = serverTimestamp();

      const colRef = collection(db, "agenda");

      // Add a new document with a generated id.
      const docRef = await addDoc(colRef, newTimingDay);
      console.log("Document written with ID: ", docRef.id);
    };

    const getRandomElement = (arr) => {
      return arr[Math.floor(Math.random() * arr.length)];
    };

    const getRandomInRange = (min, max) => {
      return Math.round(Math.random() * (max - min) + min);
    };

    const handleAdjective = () => {
      // choosenAdjective.value = getRandomElement(adjectives);
      choosenAdjective.value = getRandomElement(adjectives);
    };

    handleAdjective();

    // TODO pojawia sie poste pole, w dol jest OK, w gore pojawia sie poste
    const handleWheelAdjective = (isUp) => {
      let position = adjectives.indexOf(choosenAdjective.value);
      const limit = adjectives.length;
      // adjectives[position + 1];

      if (isUp) {
        if (position > limit) position = -1;
        choosenAdjective.value = adjectives[position + 1];
      } else {
        if (position <= 0) position = limit;
        choosenAdjective.value = adjectives[position - 1];
      }
    };

    const tagsInSentence = (titleKeywords, title) => {
      let hashtags = [];

      for (const [key, values] of Object.entries(titleKeywords)) {
        values.forEach((word) => {
          if (title.toLowerCase().includes(word.toLowerCase())) {
            hashtags.push(key);
          }
        });
      }
      return hashtags;
    };

    const checkIsTagsMinimum = (arr) => {
      // console.log("isTagsminimum");
      if (arr.length > 3) {
        isTagsMinimum.value = true;
      } else {
        isTagsMinimum.value = false;
      }
    };

    const handleRefreshTagInput = () => {
      console.log(tags.value);
      tags.value = removeEmojis(tags.value);
      tags.value = tags.value.replaceAll("+", "");
      tags.value = tags.value.toLowerCase();

      let tagsArr = tags.value.split(" ");
      let uniqueTags = [...new Set(tagsArr)];

      if (
        uniqueTags.includes("dildoing") &&
        uniqueTags.includes("suckingdildo")
      ) {
        console.log("dildoing && suckingdildo");
        uniqueTags = uniqueTags.filter((e) => e !== "suckingdildo");
        uniqueTags = uniqueTags.filter((e) => e !== "dildosucking");
        uniqueTags = uniqueTags.filter((e) => e !== "other");
        tags.value = uniqueTags.join(" ");
      } else {
        console.log("NOT dildoing && suckingdildo");
        uniqueTags = uniqueTags.filter((e) => e !== "other");
        tags.value = uniqueTags.join(" ");
      }

      let post = choosenPost.value;
      post.tags = tags.value;

      checkIsTagsMinimum(uniqueTags);
    };

    // const titleCase = (str) => {
    //   return str
    //     .toLowerCase()
    //     .split(" ")
    //     .map(function (word) {
    //       return word.charAt(0).toUpperCase() + word.slice(1);
    //     })
    //     .join(" ");
    // };

    const removeEmojis = (str) =>
      str.replace(
        /[\u{1f600}-\u{1f64f}\u{1f300}-\u{1f5ff}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f900}-\u{1f9ff}\u{1f300}-\u{1f5ff}\u{1f680}-\u{1f6ff}\u{1f400}-\u{1f4ff}\u{1f0cf}\u{1f000}-\u{1f6ff}]/gu,
        ""
      );

    const replaceQuotes = (str) => str.replace(/\"/g, "'");

    // const titleCase = (
    //   words,
    //   ignore = ["of", "and", "the", "to", "a", "in", "POV", "closeup"]
    // ) => {
    //   ignore = new Set(ignore);

    //   return words.replace(/\w+/g, (word, i) => {
    //     word = word.toLowerCase();

    //     if (i && ignore.has(word)) {
    //       return word;
    //     }

    //     return word[0].toUpperCase() + word.slice(1);
    //   });
    // };

    const titleCase = (
      words,
      ignore = [
        "of",
        "and",
        "the",
        "to",
        "a",
        "by",
        "in",
        "on",
        "POV",
        "closeup",
        "her",
        "with",
        "s",
      ]
    ) => {
      ignore = new Set(ignore);

      return words.replace(/\w+/g, (word, i) => {
        if (ignore.has(word)) {
          return word;
        }

        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });
    };

    const changeTitle = (title) => {
      let post = choosenPost.value;
      title = replaceQuotes(title);
      title = titleCase(title);
      title = removeEmojis(title);
      title = title.replace(/\[ More in Comment \]/g, "[ more in comment ]");
      post.title = title;
    };

    // const removeEmojis = (str) =>
    //   str.replace(
    //     /[\u{1f600}-\u{1f64f}\u{1f300}-\u{1f5ff}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f900}-\u{1f9ff}\u{1f300}-\u{1f5ff}\u{1f680}-\u{1f6ff}\u{1f400}-\u{1f4ff}\u{1f0cf}\u{1f000}-\u{1f6ff}]/gu,
    //     ""
    //   );

    const handleTagFromSentence = (title) => {
      // title = removeEmojis(title);
      let tagsArr = tagsInSentence(titleKeywords, title);
      tagsArr.push("camgirl");

      let uniqueTags = [...new Set(tagsArr)];
      checkIsTagsMinimum(uniqueTags);
      tags.value = uniqueTags.join(" ").replaceAll("other", "");

      let post = choosenPost.value;

      post.tags = tags.value;
    };

    const makeArrFromTagsInput = (string) => {
      let tagsArr = string.replaceAll("+", "").split(" ");
      return tagsArr;
    };

    const wheelItBetter = (event, data, func) => {
      if (event.deltaY < 0) {
        // handleAdjective();
        handleWheelAdjective(1);
      } else {
        // handleAdjective();
        handleWheelAdjective(0);
      }
    };

    const copyToTitle = (chosenPostTitle) => {
      console.log(choosenAdjective.value);
      chosenPostTitle = `${choosenAdjective.value} ${chosenPostTitle}`;
    };

    const showNeeded = () => {
      // console.log(socials);
      // console.log(socials.reddit);
      neededToday.value = [];
      // console.log(dates);
      let workingDate = dates.value["0"];
      // console.log("workingDate");
      // console.log(workingDate);
      let redditAccounts = Object.keys(socials.reddit);
      if (workingDate) {
        for (const accountName of redditAccounts) {
          // console.log(accountName);

          if (workingDate.hasOwnProperty(`reddit-${accountName}`)) {
            if (workingDate[`reddit-${accountName}`][0]) {
              let count = workingDate[`reddit-${accountName}`];
              // console.log(count);
              // console.log(`reddit ${accountName}: ${count.length}`);

              let countData = {};
              countData[`${accountName}`] = `${count.length}`;
              neededToday.value.push(countData);
            } else {
              // console.log(`reddit ${accountName}: 0`);
              let countData = {};
              countData[`${accountName}`] = `0`;
              neededToday.value.push(countData);
            }
          }
        }
      }
      // console.log("neededToday.value");
      // console.log(neededToday.value);
    };

    watchEffect(() => {
      if (dates.value) {
        // console.log(dates.value);
        showNeeded();
        // console.log("isDayDone()", isDayDone());
      }
    });

    //TODO
    const handleCopy = async (oldPost) => {
      // X3yHNx6infJKpscQwLqM
      console.log(oldPost);
      // console.log(id);

      let post = choosenPost.value;

      console.log(post);
      console.log(post["title"]);

      let newPost = {
        url: post.url,
        isDone: false,
        isScheduled: false,
        title: post.title,
        description: "",
        nsfw: true,
        socialType: "",
        accountName: "",
        flair: "",
        data: [],
        editedAt: "",
        tags: "",
        // createdAt: serverTimestamp(),
      };

      console.log(newPost);
      // const colRef = collection(db, "postsTEST");
      // const docRef = await addDoc(colRef, newPost);
      // console.log("Document written with ID: ", docRef.id);

      // let newId = docRef.id;

      const getPostbyId = async (findThisId) => {
        let allPosts = props.posts;

        for (const [key, value] of Object.entries(allPosts)) {
          for (const [subkey, subValue] of Object.entries(value)) {
            if (subkey == "id") {
              if (subValue == findThisId) {
                console.log(`${subValue}`);
                console.log(allPosts[`${key}`]);
                choosenPost.value = allPosts[`${key}`];
              }
            }
          }
        }
      };

      // choosenPost.value = await getPostbyId(newId);
      choosenPost.value = await getPostbyId("cls6NH59ta0X8a4xMGaK");
    };

    const addTag = (newTags) => {
      let allNewTags = `${tags.value} ${newTags}`;
      tags.value = allNewTags;
    };

    const isScheduledPost = (postData) => {
      if (postData.isScheduled == true) {
        return true;
      } else {
        return false;
      }
    };

    const handleUpdate = async (
      postData,
      title,
      socialType,
      accountName,
      postDateAndTime,
      tags,
      twitterAcc,
      onlySave
    ) => {
      console.log("handleUpdate");
      console.log("neededToday.value", neededToday.value);

      const docRef = doc(db, "posts", postData.id);

      // let newTitle = title;

      if (onlySave == false) {
        //NOTE save and add date
        // newTitle = addCommentTxt(title);
        // postDateAndTime
        // 2023-01-29 06:03
        // console.log(postDateAndTime);
        // console.log(postDateAndTime[0]);
        // console.log(postDateAndTime[1]);

        let newDate = convertDate(postDateAndTime[0]);
        let newTime = convertTime(postDateAndTime[1]);
        console.log("newDate, newTime", newDate, newTime);

        let newPost = {
          title: addCommentTxt(title),
          description: `${postData.url}?utm_source=${socialType}&utm_medium=organic_social&utm_campaign=${accountName}&utm_content=url`,
          nsfw: true,
          socialType: socialType,
          accountName: accountName,
          flair: flair.value,
          data: [newDate, newTime],
          isScheduled: true,
          // isScheduled: !onlySave,
          tags: tags,
          twitter: twitterAcc,
          editedAt: serverTimestamp(),
        };

        console.log(newPost);
        updateDoc(docRef, newPost);
        //
      } else {
        // NOTE SAVE
        let newPost = {
          title: title,
          description: `${postData.url}?utm_source=${socialType}&utm_medium=organic_social&utm_campaign=${accountName}&utm_content=url`,
          nsfw: true,
          socialType: socialType,
          accountName: accountName,
          flair: flair.value ? flair.value : "",
          // isScheduled: !onlySave,
          isScheduled: isScheduledPost(postData),
          tags: tags,
          twitter: twitterAcc ? twitterAcc : "",
          editedAt: serverTimestamp(),
        };

        console.log(newPost);
        updateDoc(docRef, newPost);
        //
      }
    };

    // const handleSaveTag = async (
    //   postData,
    //   socialType,
    //   accountName,
    //   tags,
    //   twitterAcc
    // ) => {
    //   const docRef = doc(db, "posts", postData.id);

    //   let newPost = {
    //     socialType: socialType,
    //     accountName: accountName,
    //     flair: flair.value,
    //     tags: tags,
    //     twitter: twitterAcc,
    //     editedAt: serverTimestamp(),
    //   };

    //   updateDoc(docRef, newPost);
    //   console.log(newPost);
    // };

    const updateInputs = () => {
      choosenPost.value = props.posts[postNum.value];
      console.log(choosenPost.value);

      let post = choosenPost.value;
      accountName.value = post.accountName;
      flair.value = post.flair;
      twitterAcc.value = post.twitter;
      title.value = post.title;
      postDate.value = dateToInputs(post.data);
      postTime.value = timeToInputs(post.data);
    };

    const prevPost = () => {
      maxNum = props.posts.length;
      // console.log(maxNum);
      if (postNum.value == 0) {
        postNum.value = maxNum - 1;
      } else {
        postNum.value--;
      }
      tags.value = "";
      updateInputs();
      // showNeeded();
    };

    const nextPost = () => {
      maxNum = props.posts.length;
      // console.log(maxNum);
      if (postNum.value == maxNum - 1) {
        postNum.value = 0;
      } else {
        postNum.value++;
      }
      tags.value = "";
      updateInputs();
      // showNeeded();
    };

    const randomPost = () => {
      maxNum = props.posts.length;
      postNum.value = getRandomInRange(0, maxNum - 1);
      tags.value = "";
      updateInputs();
    };

    const removeGif = (id) => {
      console.log(id);
      const docRef = doc(db, "posts", id);
      deleteDoc(docRef);
    };

    const makeDayDone = () => {
      // console.log(props.dates["0"].id);
      // console.log(props.dates["0"].date.seconds);
      let dateId = props.dates["0"].id;
      const docRef = doc(db, "agenda", dateId);
      updateDoc(docRef, { isDone: true });
    };

    const handleStartNewDay = async () => {
      makeDayDone();
      await addNewDayWithTimesByDate(returnNextDay(dateUsingNow.value, "+"));
    };

    onMounted(() => {
      window.addEventListener("keydown", (e) => {
        e = e || window.event;

        if (e.keyCode == "38") {
          console.log("up");
          // up arrow
        } else if (e.keyCode == "40") {
          // down arrow
          console.log("down");
        } else if (e.keyCode == "37") {
          // left arrow
          console.log("left");
          prevPost();
        } else if (e.keyCode == "39") {
          // right arrow
          console.log("right");
          nextPost();
        }
      });
    });

    return {
      title,
      postDate,
      postTime,
      socialType,
      accountName,
      flair,
      nsfw,
      handleUpdate,
      socialsData,
      today,
      choosenPost,
      nextPost,
      prevPost,
      nextHour,
      nextDay,
      getDate,
      neededToday,
      removeUsedTime,
      addNewDayWithTimes,
      createProfileUrl,
      newDayToAdd,
      format,
      postNum,
      removeGif,
      handleCopy,
      makeDayDone,
      dateUsingNow,
      choosenAdjective,
      handleAdjective,
      copyToTitle,
      wheelItBetter,
      tags,
      twitterAcc,
      addTag,
      handleTagFromSentence,
      randomPost,
      isTagsMinimum,
      makeArrFromTagsInput,
      checkIsTagsMinimum,
      handleRefreshTagInput,
      handleStartNewDay,
      changeTitle,
      // handleSaveTag,
      // handleClick,
    };
  },
};
</script>

<style scoped>
form {
  margin: 10px;
}
textarea {
  width: 100%;
  height: 56px;
  max-width: 100%;
  margin-bottom: 6px;
  padding: 10px;
  box-sizing: border-box;
  border: 1;
  border-radius: 3px;
  font-family: inherit;
  /* outline: none; */
}

input {
  padding: 5px;
  padding-left: 10px;
  /* width: 90%; */
  height: 44px;
  font-size: 1.1em;
  border-radius: 5px;
  border: 0px solid #00a75e;
  /* background-color: #ececec; */
  /* color: #2c2c2c; */
  background-color: #2c2c2c;
  color: #ececec;
  font-weight: 600;
  margin: 10px 10px 0px 0px;
  opacity: 1;
  transition-duration: 0.7s;
}

input[type="time"],
input[type="date"] {
  font-size: 1.2em;
  color: #ececec;
  background-color: #2c2c2c;
  padding-left: 10px;
  padding-right: 10px;
}

input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%) /* sepia(13%) saturate(3207%) hue-rotate(130deg) */
    brightness(95%) contrast(80%);
}

button {
  cursor: pointer;
  color: #1b1b1b;
}

img {
  border-radius: 7px;
  max-width: 600px;
  width: 100%;
}

.accountsTab {
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #353535;
  color: #ececec;
  border-radius: 500px;
  padding: 7px 15px;
  margin-right: 7px;
}
.accountsTab:hover {
  background-color: #575757;
  transition-duration: 0.3s;
}

.tag {
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #353535;
  padding: 7px 15px;
  color: #ececec;
  border-radius: 50px;
  margin-right: 7px;
}

.tag:hover {
  background-color: #575757;
  transition-duration: 0.3s;
  /* opacity: 0.6; */
}

button.chosenMaintag,
.chosenMaintag .chosenTag {
  background-color: #5ae4ca;
  color: #1b1b1b;
}

.chosenMaintag button:hover,
button.chosenMaintag:hover {
  background-color: #5ae4ca;
  transition-duration: 0.3s;
  opacity: 0.6;
}

.chosenTag {
  background-color: #5ae4ca;
  color: #1b1b1b;
}

.chosenTag:hover {
  background-color: #5ae4ca;
  transition-duration: 0.3s;
  /* opacity: 0.6; */
}

.dark {
  background-color: #1b1b1b;
}

.socialType {
  background-color: #1b1b1b;
  border: 2px solid #1b1b1b;
  color: #353535;
  height: 36px;
}

.socialType:hover {
  background-color: #353535;
  border: 2px solid #353535;
  color: #cacaca;
  transition-duration: 0.3s;
}

.socialTypeChoosen {
  background-color: #1b1b1b;
  border: 2px solid #555555;
  color: #cacaca;
  height: 36px;
}
#postDate,
#postTime {
  width: 130px;
}
#title,
#tags {
  width: 90%;
}
.tabs {
  /* margin-bottom: 25px; */
  /* padding-bottom: 10px; */
  margin-top: 20px;
}

.postnavigation {
  /* background-color: #2c2c2c; */
  /* background-color: #1b1b1b; */
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(27, 27, 27, 1) 0%,
    rgba(27, 27, 27, 1) 66%,
    rgba(27, 27, 27, 0) 100%
  );
  padding-bottom: 15px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* height: 120px; */
  display: flex;
  justify-content: space-between;
}

.postnavigation button {
  background-color: transparent;
  color: whitesmoke;
}
.postnavigation button span {
  font-size: 3rem;
}
.postnavigation button span:hover {
  font-size: 3rem;
  color: #f06;
  transition-duration: 0.3s;
}
.active {
  background-color: greenyellow;
}

.formContainer {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
}
.id {
  width: 200px;
  height: 15px;
  text-shadow: #1b1b1b;
  font-size: 12px;
  color: #777;
  position: relative;
  top: -30px;
  margin-left: 7px;
  padding: 3px;
  margin-bottom: -20px;
}

#saveBtn {
  width: 75px;
  height: 75px;
  padding: 0px;
  border-radius: 300px;
  text-align: center;
  background-color: #00a75e;
  transition-duration: 0.3s;
}
#saveBtn span:hover {
  color: whitesmoke;
}

#saveBtn span {
  font-size: 2rem;
}

#saveBtn:hover {
  background-color: #00c570;
  transition-duration: 0.3s;
}

#copyBtn {
  width: 75px;
  height: 75px;
  padding: 0px;
  border-radius: 300px;
  text-align: center;
  background-color: #353535;
  transition-duration: 0.3s;
}
#copyBtn span {
  font-size: 2rem;
}

#copyBtn span:hover {
  color: whitesmoke;
}

#copyBtn:hover {
  background-color: #00c570;
  transition-duration: 0.3s;
}

.todoInfo {
  /* background-color: #2c2c2c; */
  margin: 10px 10px 20px 0px;
  padding: 5px;
  border-radius: 5px;
  color: rgb(173, 173, 173);
  /* width: 90%; */
}
.todoInfo span.cont {
  display: inline;
  margin-right: 25px;
  padding: 5px;
}
.newDate {
  margin: 650px 0 150px 0px;
}

.newDate button {
  border: 2px solid #1b1b1b;
  transition-duration: 0.3s;
}
.newDate button:hover {
  background-color: #1b1b1b;
  color: #5ae4ca;
  border: 2px solid #5ae4ca;
  transition-duration: 0.3s;
}

.removeBtn {
  margin-bottom: 300px;
  border: 2px solid rgb(209, 0, 0);
  color: rgb(209, 0, 0);
  background-color: #1b1b1b;
}
.removeBtn:hover {
  background-color: rgb(209, 0, 0);
  color: #ffffff;
  transition-duration: 0.3s;
}

.active {
  background-color: #00c570;
  transition-duration: 0.3s;
  color: #353535;
}
.nonactive {
  background-color: #353535;
  color: whitesmoke;
  transition-duration: 0.3s;
}

h3 {
  color: #575757;
  margin: 0px 0px 0px 5px;
  font-weight: 900;
}
.handler {
  margin: 7px 25px 7px -35px;
  color: grey;
}

.handler:hover {
  cursor: pointer;
  color: white;
  transition-duration: 0.2s;
}

::selection {
  color: none;
  background: none;
}
/* For Mozilla Firefox */
::-moz-selection {
  color: none;
  background: none;
}
input::selection {
  color: white;
  background: #575757;
}
/* For Mozilla Firefox */
input::-moz-selection {
  color: white;
  background: #575757;
}

.glass {
  background: none;

  border: 0px solid #575757;
  transition-duration: 0.3s;
}
.glass:hover {
  border: 0px solid grey;
  transition-duration: 0.3s;
  border-radius: 500px;
  padding-top: 10px;
}
.error {
  color: red;
  transition-duration: 0.5s;
}
.dice {
  width: 56px;
  height: 56px;
  line-height: 56px;
  position: fixed;
  bottom: 70px;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  font-size: 3rem;
}
.datatime {
  opacity: 5%;
}
.cont {
  background-color: #c7c7c7;
  border-radius: 100px;
  color: #2c2c2c;
  padding: 0px;
  text-align: center;
  font-weight: 600;
  width: 19px;
  height: 19px;
  display: inline-block;
  line-height: 19px;
  font-size: 0.7rem;
  margin-right: -7px;
}
.isScheduled {
  /* border-right: 3px solid #00c570; */
  background-color: #003a21;
  /* display: inline; */
  /* height: 44px;
  margin-left: -45px; */
}

#saveBtn2 {
  transition-duration: 0.7s;
  background-color: #00ffd0;
}
#saveBtn2:hover {
  transition-duration: 0.3s;
  /* opacity: 0.6; */
  /* background-color: #00ffd0; */
  background-color: #fff;
}
#saveBtn2:active {
  transition-duration: 1s;
  background-color: #00ffd0;
  box-shadow: 0px 0px 50px 5px rgba(0, 255, 208, 0.5);
}
</style>
