import { createRouter, createWebHistory } from "vue-router";

// view components
import Welcome from "../views/Welcome.vue";
import Todo from "../views/Todo.vue";
import AddUrls from "../views/AddUrls.vue";
import GetCode from "../views/GetCode.vue";

// firebase imports
import { auth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next({ name: "Welcome" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/todo",
    name: "todo",
    component: Todo,
    beforeEnter: requireAuth,
  },
  {
    path: "/addurls",
    name: "addurls",
    component: AddUrls,
    beforeEnter: requireAuth,
  },
  {
    path: "/getcode",
    name: "getcode",
    component: GetCode,
    beforeEnter: requireAuth,
  },
];
// https://web2sex.com/upload/models/03/sexygames/3-sexygames.mp4_1667933971.gif
// https://web2sex.com/upload/models/79/miamorisonn/3-miamorisonn.mp4_1667847618.gif

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
