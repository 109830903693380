// Timings ALL DAY changes
// const timings = {
//   reddit: {
//     web2sex: [
//       // "0101AM",
//       // "0333AM",
//       // "0606AM",
//       // "1010AM",
//       "1111AM",
//       "0101PM",
//       "0404PM",
//       "0606PM",
//       "0808PM",
//       "0909PM",
//     ],
//     suckingteens: [
//       // "0000AM",
//       // "0303AM",
//       // "0808AM",
//       // "0131PM",
//       "0202PM",
//       "0303PM",
//       "0434PM",
//       "0707PM",
//       "1010PM",
//       "1111PM",
//     ],
//     wtfcammoments: [
//       "0011AM",
//       // "1212PM"
//     ],
//     juicyteens: [
//       // "0202AM",
//       // "0404AM",
//       // "0707AM",
//       // "0909AM",
//       // "0303PM",
//       // "0505PM",
//       // "0636PM",
//       // "0737PM",
//       "0838PM",
//       "1141PM",
//     ],
//     wetfingerbabes: [
//       "0203AM",
//       "0405AM",
//       "0708AM",
//       "0910AM",
//       "0304PM",
//       "0506PM",
//       "0637PM",
//       "0738PM",
//       "0839PM",
//       "1142PM",
//     ],
//   },
//   twitter: {
//     web2sex: [],
//     lesbianpink: [],
//   },
//   tumbrl: {
//     tumbrl_account1: [],
//     tumbrl_account2: [],
//   },
// };

//Timings ALL DAY
const timings = {
  reddit: {
    web2sex: [
      "0101AM",
      "0402AM",
      "0603AM",
      "0801AM",
      "0102PM",
      "0303PM",
      "0501PM",
      "0702PM",
      "0903PM",
      "1101PM",
    ],
    suckingteens: [
      "0101AM",
      "0402AM",
      "0603AM",
      "0801AM",
      "0102PM",
      "0303PM",
      "0501PM",
      "0702PM",
      "0903PM",
      "1101PM",
    ],
    wtfcammoments: ["0101AM", "0101PM"],
    juicyteens: [
      "0101AM",
      "0402AM",
      "0603AM",
      "0801AM",
      "0102PM",
      "0303PM",
      "0501PM",
      "0702PM",
      "0903PM",
      "1101PM",
    ],
    magicwandgirls: [
      "0101AM",
      // "0402AM",
      "0603AM",
      // "0801AM",
      "0102PM",
      // "0303PM",
      "0501PM",
      // "0702PM",
      "0903PM",
      // "1101PM",
    ],
    wetfingerbabes: [
      "0101AM",
      "0402AM",
      "0603AM",
      "0801AM",
      "0102PM",
      "0303PM",
      "0501PM",
      "0702PM",
      "0903PM",
      "1101PM",
    ],
    tastymuffins: [
      "0101AM",
      // "0402AM",
      "0603AM",
      // "0801AM",
      "0102PM",
      // "0303PM",
      "0501PM",
      // "0702PM",
      "0903PM",
      // "1101PM",
    ],
  },
  twitter: {
    web2sex: [],
    lesbianpink: [],
  },
  tumbrl: {
    tumbrl_account1: [],
    tumbrl_account2: [],
  },
};

// Timings PM
// const timings = {
//   reddit: {
//     web2sex: ["0111PM", "0414PM", "0616PM", "0818PM", "0919PM"],
//     suckingteens: [
//       "0141PM",
//       "0212PM",
//       "0313PM",
//       "0444PM",
//       "0717PM",
//       "1020PM",
//       "1121PM",
//     ],
//     wtfcammoments: [],
//     juicyteens: ["0313PM", "0515PM", "0646PM", "0747PM", "0848PM", "1151PM"],
//   },
//   twitter: {
//     web2sex: [],
//     lesbianpink: [],
//   },
//   tumbrl: {
//     tumbrl_account1: [],
//     tumbrl_account2: [],
//   },
// };

export default timings;
