<template>
  <a class="menuBtn" @click="showMenu = !showMenu" v-if="showMenu == false">
    <span class="material-symbols-outlined"> menu </span>
  </a>
  <a class="menuBtn" @click="showMenu = !showMenu" v-if="showMenu == true">
    <span class="material-symbols-outlined"> close </span>
  </a>
  <nav v-if="showMenu == true">
    <div class="menuText">
      <router-link :to="{ name: 'todo' }">todo</router-link><br />
      <router-link :to="{ name: 'addurls' }">+ gifs</router-link><br />
      <router-link :to="{ name: 'getcode' }">{ get code }</router-link>
      <p>{{ user.email }}</p>
      <a @click="handleClick">logout</a>
    </div>
  </nav>
</template>

<script>
// firebase imports
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";
import { ref } from "@vue/reactivity";

export default {
  setup() {
    const router = useRouter();
    const { logout, error } = useLogout();
    const { user } = getUser();
    const showMenu = ref(false);

    const handleClick = async () => {
      await logout();
      if (!error.value) {
        console.log("user logged out");
        // router.push("/todo");
        router.push("/");
      }
    };
    return { handleClick, user, showMenu };
  },
};
</script>

<style scoped>
nav {
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background-color: #444;
  color: antiquewhite;
  position: fixed;
  z-index: 99;
}
nav p {
  margin: 2px auto;
  font-size: 16px;
  color: #444;
}
nav p.email {
  font-size: 14px;
  color: #999;
}
div {
  /* text-align: center; */
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 50px; */
}
a {
  color: antiquewhite;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  color: antiquewhite;
  opacity: 0.4;
  transition-duration: 0.3s;
}
.menuBtn {
  width: 46px;
  height: 46px;
  background-color: #444;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  top: 15px;
  right: 15px;
}
.menuText {
  text-align: center;
  font-size: 2rem;
  line-height: 5rem;
  /* width: 100%;
  height: 100%; */
}
</style>
