import { ref, watchEffect } from "vue";

// firebase imports
import { db } from "../firebase/config";
import {
  collection,
  query,
  where,
  limit,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const getDates = (c, isDone) => {
  const documents = ref(null);

  // collection reference
  let collectionRef = collection(db, c);

  // TODO sprawdz jaka jest data dzisiaj i zrob wyszukanie na date minimum jutrzejsza

  let colRef = query(
    collectionRef,
    // orderBy("date", "desc"),
    where("isDone", "==", isDone),
    limit(1)
  );
  // let colRef = query(collectionRef, where("isDone", "==", false));
  // colRef = query(colRef, orderBy(ob, "desc"), limit(l));

  const unsub = onSnapshot(colRef, (snapshot) => {
    let results = [];
    snapshot.docs.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    // update values
    documents.value = results;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  // console.log("getDates");
  // console.log(documents.value);
  return { documents };
};

export default getDates;
