const titleKeywords = {
  lesbian: ["lesbian", "lesbians"],
  perfect: ["perfect"],
  beautiful: ["beautiful"],
  cute: ["cute"],
  skinny: ["skinny"],
  bbw: ["bbw"],

  blond: ["blond"],
  brunette: ["brunette"],
  redhead: ["redhead"],

  asian: ["asian"],
  latino: ["latino", "latina"],
  teen: ["teen", "young"],
  milf: ["milf"],
  camgirl: ["camgirl"],
  ebony: ["ebony"],

  feet: ["feet", "foot"],
  blowjob: [
    "sucking cock",
    "bj",
    "blowjob",
    "sucking bf cock",
    "cock sucking",
    "sucks cock",
  ],
  dildoing: ["dildo fucking", "dildoing", "riding dildo"],
  suckingdildo: ["dildo sucking", "dildo"],
  analdildo: ["anal dildo", "dildo in the ass"],
  fingering: ["fingering", "rubbing"],
  spanking: ["spank", "spanks", "spanking"],
  masturbation: ["masturbation", "masturbates"],
  bigtits: ["big tits", "big boobs", "big natural"],
  fucking: ["fucking"],
  anal: ["anal"],
  perfecttits: ["perfect tits"],
  orgasm: ["orgasm"],
  fisting: ["fisting"],
  facefuck: ["facefuck"],
  cowgirl: ["cowgirl"],
  doggy: ["doggy"],
  gangbang: ["gangbang"],
  handjob: ["handjob"],
  deepthroat: ["deepthroat"],
  buttplug: ["buttplug"],
  lollipop: ["lollipop", "lolipop"],
  kissing: ["kissing"],
  breathplay: ["breathplay"],
  squirting: ["squirting", "squirt"],
  magicwand: ["magicwand", "magic"],
};

export default titleKeywords;
