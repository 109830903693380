<template>
  <Navbar />
  <!-- <img :src="post.url" /> -->
  <PostForm :posts="posts" :dates="dates" />
  <!-- <div class="container"></div> -->
</template>

<script>
import Navbar from "../components/Navbar.vue";
import PostForm from "../components/PostForm.vue";
import { ref } from "@vue/reactivity";
// import getCollection from "@/composables/getCollection";
import getTodo from "@/composables/getTodo";
import getDates from "@/composables/getDates";

export default {
  components: { Navbar, PostForm },
  setup() {
    // const { documents: posts } = getCollection("posts", "url", 3);
    const { documents: posts } = getTodo("posts");
    // const { documents: posts } = getTodo("postsTEST");
    const { documents: dates } = getDates("agenda", false);

    return { posts, dates };
  },
};
</script>

<style></style>
