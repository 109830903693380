<template>
  <Navbar />
  <div class="container">
    <h2>Add GIF urls to DB</h2>

    <form @submit.prevent="handleSubmit">
      <textarea
        name="title"
        id="urls"
        placeholder="Add GIF Urls"
        v-model="urls"
      >
      </textarea
      ><br />
      <button>Add URLs</button>
    </form>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { ref } from "@vue/reactivity";

//firebase imports
import { db } from "../firebase/config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

export default {
  components: { Navbar },
  setup() {
    const urls = ref("");

    // const handleSubmit = async () => {
    //   let urlsArray = [];
    //   urlsArray = urls.value.split("\n");
    //   console.log(urlsArray);
    // };

    const handleSubmit = async () => {
      let urlsArray = [];
      urlsArray = urls.value.split("\n");
      console.log(urlsArray);

      const colRef = collection(db, "posts");

      for (const url of urlsArray) {
        console.log(url);
        await addDoc(colRef, {
          url: url,
          isDone: false,
          isScheduled: false,
          title: "",
          description: "",
          nsfw: true,
          socialType: "",
          accountName: "",
          flair: "",
          data: [],
          editedAt: "",
          createdAt: serverTimestamp(),
        });
      }
      //reset form
      urls.value = "";
    };
    return { handleSubmit, urls };
  },
};
</script>

<style>
nav {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav p {
  margin: 2px auto;
  font-size: 16px;
  color: #444;
}
nav p.email {
  font-size: 14px;
  color: #999;
}
</style>
