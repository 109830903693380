const socials = {
  reddit: {
    web2sex: [
      "🤞 fingering",
      "🥒 dildoing",
      "🍆 sucking",
      "😋 licking",
      "❤️‍🔥 tempting",
      "👁️ show up",
      "🎯 other",
      "masturbating",
      "👉👌 fucking",
      "🥒🍩 anal dildo",
      "🦶 footjob",
      "🍩🖕 ass fingering",
      "💦 squirt",
      "🥑😋 pussy licking",
    ],
    suckingteens: [
      "🍆 cock sucking",
      "🥒 dildo sucking",
      "🖕 finger sucking",
      "🗡️ deepthroat",
      "👌 handjob",
      "🍒 tit sucking",
    ],
    wtfcammoments: ["wtf"],
    magicwandgirls: [
      "✨ magic wand",
      "✨ + 🥒 dildoing",
      "✨ + 🥒🍩 anal dildo",
      "✨ + 👉👌 fucking",
      "✨ + 🤞 fingering",
    ],
    tastymuffins: [
      "🧁 muffins",
      "🧁 + 🤏 touching",
      "🧁 + 🍌 fruits",
      "🧁 + 🍄 cock",
      "🧁 + 🍌🍩 chocolate",
      "🧁 + 💦 souce",
      "🧁 + 😋 eating",
    ],
    // hotjuicyteens: [
    //   "hotjuicyteens",
    //   "fingering",
    //   "dildoing",
    //   "anal dildo",
    //   "sucking",
    //   "licking",
    //   "tempting",
    //   "show up",
    //   "other",
    //   "masturbating",
    //   "fucking",
    // ],
    // juicyteens: ["juicyteens"],
    wetfingerbabes: ["wet finger babes"],
  },
  twitter: {
    web2sex: ["twitter_web2sex-1", "twitter_web2sex-2"],
    suckingteens: ["twitter_web2sex2-1", "twitter_web2sex2-2"],
    LesbianPinkLIVE: ["twitter_web2sex2-1", "twitter_web2sex2-2"],
  },
  // tumbrl: {
  //   tumbrl_account1: ["1", "11"],
  //   tumbrl_account2: ["2", "22"],
  // },
};

export default socials;
