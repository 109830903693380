import { ref, watchEffect } from "vue";

// firebase imports
import { db } from "../firebase/config";
import {
  collection,
  query,
  where,
  limit,
  onSnapshot,
} from "firebase/firestore";

const getScheduled = (c, limitNum) => {
  const documents = ref(null);

  // collection reference
  let collectionRef = collection(db, c);

  let colRef = query(
    collectionRef,
    where("isScheduled", "==", true),
    where("isDone", "==", false)
  );
  // colRef = query(colRef, orderBy(ob, "desc"), limit(l));

  const unsub = onSnapshot(colRef, (snapshot) => {
    let results = [];
    snapshot.docs.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    // update values
    documents.value = results;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { documents };
};

export default getScheduled;
