<template>
  <Navbar />
  <div class="container">
    <h2>{ get code }</h2>
    <!-- <input
      type="date"
      id="findbydate"
      name="text"
      v-model="dateToFind"
      class="date"
      required
    />
    <input
      type="text"
      id="findbydateText"
      name="text"
      v-model="dateToFindText"
      class="date"
      required
    /> -->

    <!-- <div>
      <div v-for="post in postsWhere" :key="post.id">
        <input type="checkbox" :id="post.id" :name="post.id" :value="post.id" />
        {{ post.id }}<br />
        {{ post.title }}<br />{{ post.socialType }} {{ post.accountName
        }}<br /><br />
      </div>
    </div> -->

    <!-- <p>{{ dateToFindText }}</p> -->
    <textarea
      name="title"
      id="urls"
      placeholder="object code"
      v-model="allCode"
    >
    </textarea
    ><br />
    <button @click="findByDate">find by date</button>
    <button @click="makeCode">make code</button>
    <button @click="markAsScheduled">mark as Scheduled</button>
  </div>
  <span v-for="post in posts" :key="post.id">
    {{ post.url }} :: {{ post.isScheduled }}
    <!-- {{ post.createdAt }} -->
    <br
  /></span>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { ref } from "@vue/reactivity";

//firebase imports

import { db, serverTimestamp } from "../firebase/config";
import { doc, updateDoc, addDoc, collection, where } from "firebase/firestore";

import getScheduled from "@/composables/getScheduled";
import getTodoWhere from "@/composables/getTodoWhere";
import { computed, watchEffect } from "@vue/runtime-core";

export default {
  components: { Navbar },
  setup() {
    let allCode = ref("");
    let allPosts = ref("");
    let dateToFind = ref("");
    let dateToFindText = ref("");
    let dateConverted = ref("");
    // let foundPosts = ref("");

    const { documents: posts } = getScheduled("posts");

    const makeCode = () => {
      console.log(posts.value);
      let allposts = posts.value;
      // foundPosts = postsWhere.value;

      for (const post of allposts) {
        console.log(post);

        let postData = post.data[0];
        let postTime = post.data[1];

        let newUrl = post.url.replace("web2sex.com", "lesbianpink.live");

        let code = `
      {
        title: "${post.title}",
        description: "${post.description}",
        nsfw: ${post.nsfw},
        subreddit: "${post.accountName}",
        twitter: "${post.twitter}",
        flair: "${post.flair}",
        data: ["${postData}", "${postTime}"],
        scheduled: ${post.isScheduled},
        tags: "${post.tags.trim()}",
        url: "${post.url}",
      },
        `;
        allCode.value = allCode.value + code;
      }
    };

    const handleUpdate = async (postData) => {
      const docRef = doc(db, "posts", postData.id);

      let newPost = {
        isDone: true,
        editedAt: serverTimestamp(),
      };

      updateDoc(docRef, newPost);
      console.log(newPost);
    };

    const markAsScheduled = async () => {
      let allposts = posts.value;
      for (const post of allposts) {
        console.log(post);
        await handleUpdate(post);
      }
    };

    return {
      posts,
      markAsScheduled,
      allCode,
      makeCode,
      dateToFind,
      // findByDate,
      // dateToFindText,
      // postsWhere,
    };
  },
};
</script>

<style>
#urls {
  width: 100%;
  height: 500px;
}
button {
  cursor: pointer;
}
</style>
