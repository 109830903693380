import { ref } from "vue";

// firebase imports
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

const error = ref(null);
const isPending = ref(false);

const logout = async (email, password) => {
  error.value = null;
  isPending.value = true;

  try {
    console.log("signing out");

    await signOut(auth);
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
    isPending.value = false;
  }
};

const useLogout = () => {
  return { error, logout, isPending };
};

export default useLogout;
